import { Cross } from 'shared/ui/Cross'
import cls from './SubmitWindow.module.scss'
import whaleSubmit from 'shared/assets/images/whaleHeart.png'
import { Button } from 'shared/ui/Button'
import React from 'react'
import whaleCry from 'shared/assets/images/whaleCry.png'

export const SubmitWindow = (props) => {
    const { onCloseSubmit, antispam } = props

    let titleText = 'Спасибо за новую мечту'
    let secondText = 'В ближайшие дни она вдохновит многих китов'
    let textButton = 'Ура!'
    let image = whaleSubmit

    if (antispam) {
        titleText = 'Я не успеваю запоминать так много'
        secondText = 'Попробуй прислать мне свою мечту немного позже'
        textButton = 'Хорошо, зайду позже'
        image = whaleCry
    }

    return (
        <div className={cls.SubmitWindow}>
            <Cross onClose={onCloseSubmit} />
            <img src={image} className={cls.ImageSubmit} alt="submitWhale" />
            <div className={cls.TitleTextSubmit}>{titleText}</div>
            <div className={cls.SecondTextSubmit}>{secondText}</div>
            <Button
                onClick={onCloseSubmit}
                className={cls.SubmitWindowButton}
                text={textButton}
            />
        </div>
    )
}
