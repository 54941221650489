import { Button } from 'shared/ui/Button'
import { Text, TextSize, TextWeight } from 'shared/ui/Text/Text'
import cls from './BottomButton.module.scss'
import { useNavigate } from 'react-router-dom'
import whale from 'shared/assets/images/whaleGreeting.png'

export const BottomButton = (props) => {
    const { readonly } = props

    const navigate = useNavigate()

    const onRedirectToSuggest = () => {
        navigate('/suggest')
    }

    const onRedirectToMain = () => {
        navigate('/')
    }

    if (readonly) {
        return (
            <div className={cls.BottomButton} style={{ marginTop: '39px' }}>
                <img
                    style={{ height: '160px', width: '220px' }}
                    src={whale}
                    alt="whale"
                ></img>
                <Text
                    className={cls.TitleText}
                    weight={TextWeight.BOLD}
                    size={TextSize.XL}
                >
                    Твои мечты уже в TagLife! Найдем их?
                </Text>
                <Button
                    onClick={onRedirectToMain}
                    className={cls.Button}
                    text="Найти мечту"
                />
            </div>
        )
    }

    return (
        <div className={cls.BottomButton}>
            <Text
                className={cls.TitleText}
                weight={TextWeight.BOLD}
                size={TextSize.XL}
            >
                Хочешь увидеть свою мечту в TagLife?
            </Text>
            <Button
                onClick={onRedirectToSuggest}
                className={cls.Button}
                text="Создать свою мечту"
            />
        </div>
    )
}
