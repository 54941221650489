import { Button } from 'shared/ui/Button'
import { ButtonType, ColorEnum } from 'shared/ui/Button/ui/Button'
import cls from './ButtonProfile.module.scss'
import { classNames } from 'shared/lib/classNames/classNames'
import { Text, TextSize, TextWeight } from 'shared/ui/Text/Text'
import plus from 'shared/assets/icons/plus.svg'
import check from 'shared/assets/icons/check-24.svg'
import star from 'shared/assets/icons/star-24.svg'
import box from 'shared/assets/icons/box-24.svg'
import telegramIcon from 'shared/assets/icons/telegramNew.svg'

export const ButtonProfile = (props) => {
    const {
        counterAccept,
        onSwitchAccepted,
        counterWant,
        onSwitchWanted,
        className,
        readonly,
        addFriend,
        myFriends,
        userId,
        onToggleWant,
        onToggleCol,
        secondSwitcher,
        telegram,
    } = props

    let added
    if (readonly) {
        if (myFriends.includes(userId)) {
            added = true
        } else {
            added = false
        }
    }

    const onClickTelegram = () => {
        window.open(`https://t.me/${telegram}`)
    }

    return (
        <div className={classNames(cls.ButtonContainer, {}, [className])}>
            <div className={cls.UpperButtonContainer}>
                {!readonly ? null : (
                    <Button
                        type={ButtonType.PROFILE}
                        onClick={addFriend}
                        className={
                            added
                                ? cls.ButtonProfileAdded
                                : cls.ButtonProfileNotAdded
                        }
                    >
                        <div className={cls.Container}>
                            <img
                                src={added ? check : plus}
                                alt="check"
                                className={cls.IconFriend}
                            />
                            <Text
                                className={cls.Counter}
                                weight={TextWeight.SBOLD}
                                size={TextSize.S}
                            >
                                {added ? 'В друзьях' : 'В друзья'}
                            </Text>
                        </div>
                    </Button>
                )}
                {telegram && (
                    <Button
                        className={cls.telegramButton}
                        color={ColorEnum.WHITE}
                        onClick={onClickTelegram}
                    >
                        <img src={telegramIcon} className={cls.telegramIcon} />
                    </Button>
                )}
            </div>
            <div className={cls.ButtonWrapper}>
                <Button
                    type={ButtonType.PROFILE}
                    text={'Достигнуто'}
                    color={ColorEnum.WHITE}
                    counter={counterAccept}
                    onClick={onSwitchAccepted}
                    className={cls.ButtonProfile}
                />
                <Button
                    type={ButtonType.PROFILE}
                    text={'Создано'}
                    color={ColorEnum.WHITE}
                    counter={counterWant}
                    onClick={onSwitchWanted}
                    className={cls.ButtonProfile}
                />
            </div>
            <div className={cls.ChooserWrapper}>
                <button
                    onClick={onToggleWant}
                    className={classNames(cls.Chooser, {}, [
                        secondSwitcher === 'want'
                            ? cls.ChooserLeftActive
                            : null,
                    ])}
                >
                    <div className={cls.ButtonContentWrapper}>
                        <img className={cls.Icon} src={star} alt="icon" />
                        <Text weight={TextWeight.SBOLD} size={TextSize.S}>
                            Мечты
                        </Text>
                    </div>
                </button>
                <button
                    onClick={onToggleCol}
                    className={classNames(cls.Chooser, {}, [
                        secondSwitcher === 'collection'
                            ? cls.ChooserRightActive
                            : null,
                    ])}
                >
                    <div className={cls.ButtonContentWrapper}>
                        <img className={cls.Icon} src={box} alt="icon" />
                        <Text weight={TextWeight.SBOLD} size={TextSize.S}>
                            Коллекции
                        </Text>
                    </div>
                </button>
            </div>
        </div>
    )
}
