import { FloatModal } from 'shared/ui/FloatModal'
import { Text, TextAlign, TextSize, TextWeight } from 'shared/ui/Text/Text'
import cls from './GreetingFloat.module.scss'
import whale from 'shared/assets/images/whaleGreeting.png'
import { Button } from 'shared/ui/Button'
import React from 'react'

export const GreetingFloat = (props) => {
    const { closeGreetingFloat } = props

    return (
        <FloatModal onClose={closeGreetingFloat}>
            <img className={cls.FloatWhale} src={whale} alt="whale" />
            <Text
                className={cls.TitleTextFloat}
                size={TextSize.XL}
                align={TextAlign.LEFT}
                weight={TextWeight.BOLD}
            >
                О чем ты мечтаешь?
            </Text>
            <Text
                className={cls.TextFloat}
                align={TextAlign.LEFT}
                weight={TextWeight.MED}
            >
                Мир полон удивительных мечт. Давай найдём твои!
            </Text>
            <Button
                onClick={closeGreetingFloat}
                className={cls.FloatButton}
                text={'Искать мечты'}
            />
        </FloatModal>
    )
}
