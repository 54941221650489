import { FloatModal } from 'shared/ui/FloatModal'
import cls from './FloatModalRequest.module.scss'
import { Button } from 'shared/ui/Button'
import whale from 'shared/assets/images/whaleGlasses.png'
import { Text, TextAlign, TextSize, TextWeight } from 'shared/ui/Text/Text'
import React from 'react'

export const FloatModalRequest = (props) => {
    const { onCloseFloatModal } = props

    return (
        <FloatModal onClose={onCloseFloatModal}>
            <img className={cls.FloatWhale} src={whale} alt="whale" />
            <Text
                className={cls.TitleTextFloat}
                size={TextSize.XL}
                align={TextAlign.LEFT}
                weight={TextWeight.BOLD}
            >
                Ты решаешь, что будет в ленте
            </Text>
            <Text
                className={cls.TextFloat}
                align={TextAlign.LEFT}
                weight={TextWeight.MED}
            >
                Создай свою мечту и её увидят все пользователи TagLife!
            </Text>
            <Button
                onClick={onCloseFloatModal}
                className={cls.FloatButton}
                text={'Погнали!'}
            />
        </FloatModal>
    )
}
