import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import React from 'react'

// prod

const firebaseConfig = {
    apiKey: 'AIzaSyB-1YIc41Y_gUtd8rZcDSbmZXCcJ28acbA',
    authDomain: 'taglife-ebf0b.firebaseapp.com',
    databaseURL: 'https://taglife-ebf0b.firebaseio.com',
    projectId: 'taglife-ebf0b',
    storageBucket: 'taglife-ebf0b.appspot.com',
    messagingSenderId: '72025364709',
    appId: '1:72025364709:web:3238d31276b67fffac9c0c',
    measurementId: 'G-SNXB7K6ZG9',
}

// dev

// const firebaseConfig = {
//     apiKey: 'AIzaSyAiegRmQubJb7BvI0yhAwoWp3BJNQtQpTs',
//     authDomain: 'taglife-test.firebaseapp.com',
//     projectId: 'taglife-test',
//     storageBucket: 'taglife-test.appspot.com',
//     messagingSenderId: '642609487684',
//     appId: '1:642609487684:web:2dbda891309339d93f8111',
//     measurementId: 'G-T1ND7Z0XPN',
// }

export const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
// 'gs://taglife-images'
export const imageDb = getStorage(app, 'gs://taglife-images') // изменить для прод
// export const messaging = getMessaging(app); // раскомментить в прод

export const FireBaseProvider = (props) => {
    const { children } = props

    return <>{children}</>
}
