import GridCollection from '../GridCollection/GridCollection'
import { useDispatch, useSelector } from 'react-redux'
import cls from '../CollectionPage.module.scss'
import {
    authActions,
    getAcceptedMoments,
    getAllCards,
    getRejectedMoments,
    getWantedMoments,
} from 'features/anonymousAuth'
import { useCallback, useEffect, useRef, useState } from 'react'
import { ButtonProfile } from 'widgets/ButtonProfile/ButtonProfile'
import { CollectionContainer } from '../CollectionContainer/CollectionContainer'
import { navActions } from 'entities/NavMenu'
import { shareFunc } from 'shared/lib/shareFunc/shareFunc'
import { useNavigate, useParams } from 'react-router-dom'
import {
    Text,
    TextAlign,
    TextColor,
    TextSize,
    TextWeight,
} from 'shared/ui/Text/Text'
import { Button } from 'shared/ui/Button'
import inside from 'shared/assets/icons/inside.svg'
import { DragDrop } from 'widgets/DragDrop/DragDrop'
import { fetchOtherProfileData } from 'features/otherProfileData/model/services/fetchOtherProfileData'
import { Loader } from 'shared/ui/Loader'
import bookmark from 'shared/assets/icons/bookmark.svg'
import chevron from 'shared/assets/icons/chevron-left-24.svg'
import bookmarkRed from 'shared/assets/icons/bookmark-red.svg'
import { Popup } from 'shared/ui/Popup/Popup'
import { copyTextToClipboard } from 'shared/lib/copyTextToTextClipboard/copyTextToTextClipboard'
import { Banner } from 'widgets/Banner/Banner'
import { ColorEnum } from 'shared/ui/Button/ui/Button'
import share from 'shared/assets/icons/share-32.svg'
import {
    getOtherAcceptedCardData,
    getOtherCreatedCardData,
    getOtherWantedCardData,
    getOtherWantedCardDataWithMyPicks,
    getSelectCardsInOtherCollection,
} from 'app/providers/LoadDataProvider/ui/selectors'
import { WantContainer } from '../WantContainer/WantContainer'
import copyIcon from 'shared/assets/icons/copyIcon.svg'
import React from 'react'

const OtherProfilePage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    let { id } = useParams()

    if (/^\d+$/.test(id)) {
        id = +id
    }

    const status = useSelector((state) => state.otherProfileData.status)
    const error = useSelector((state) => state.otherProfileData.error)

    useEffect(() => {
        dispatch(fetchOtherProfileData(id))
    }, [dispatch])

    let allCards = JSON.parse(JSON.stringify(useSelector(getAllCards)))
    let acceptedMoments = useSelector(
        (state) => state.otherProfileData.acceptedMoments
    )
    let wantedMoments = useSelector(
        (state) => state.otherProfileData.wantedMoments
    )
    let rejectedMoments = useSelector(
        (state) => state.otherProfileData.rejectedMoments
    )
    let createdMoments = useSelector(
        (state) => state.otherProfileData.createdMoments
    )
    let pinnedMoments = useSelector(
        (state) => state.otherProfileData.pinnedMoments
    )

    const allMoments = acceptedMoments.concat(wantedMoments, rejectedMoments)

    const collections = useSelector((state) => state.collection.collections)

    const userId = useSelector((state) => state.otherProfileData.id)
    const name = useSelector((state) => state.otherProfileData.name)
    const avatar = useSelector((state) => state.otherProfileData.avatar)
    const descr = useSelector((state) => state.otherProfileData.descr)
    const nickname = useSelector((state) => state.otherProfileData.nickname)
    const background = useSelector((state) => state.otherProfileData.background)
    const telegram = useSelector((state) => state.otherProfileData.telegram)

    const myNickname = useSelector((state) => state.auth.nickname)
    const myId = useSelector((state) => state.auth.id)
    const myFriends = useSelector((state) => state.auth.friends)
    const myAcceptedMoments = useSelector(getAcceptedMoments)
    const myWantedMoments = useSelector(getWantedMoments)
    const myRejectedMoments = useSelector(getRejectedMoments)
    const myAllMoments = myAcceptedMoments.concat(
        myWantedMoments,
        myRejectedMoments
    )

    const parsedCollections = useSelector(getSelectCardsInOtherCollection)

    console.log(avatar, background)

    // useEffect(() => {
    //     if (userId === undefined) {
    //         navigate('/notfound')
    //     }
    // }, [])
    // console.log(id, userId)

    const [switcher, setSwitcher] = useState(null)
    const [fixedHeader, setFixedHeader] = useState(true)

    const [secondSwitcher, setSecondSwitcher] = useState(null)

    const [visible, setVisible] = useState(false)

    useEffect(() => {
        let secondSwitcherFriend = sessionStorage.getItem(
            'secondSwitcherFriend'
        )

        if (secondSwitcherFriend) {
            setSecondSwitcher(secondSwitcherFriend)
        } else {
            sessionStorage.setItem('secondSwitcherFriend', 'want')
            setSecondSwitcher('want')
        }
    }, [])

    useEffect(() => {
        dispatch(navActions.setVisible(false))
    }, [dispatch])

    const wantedCardData = useSelector(getOtherWantedCardData)
    const acceptedCardData = useSelector(getOtherAcceptedCardData)
    const createdCardData = useSelector(getOtherCreatedCardData)

    const wantedCardDataWithMyPicks = useSelector(
        getOtherWantedCardDataWithMyPicks
    )

    let header

    if (switcher === 'accepted') {
        header = 'Достигнуто'
    } else if (switcher === 'created') {
        header = 'Создано'
    }

    let counterCreate = createdCardData.length
    let counterAccept = acceptedCardData.length

    const onToggleWant = () => {
        setSecondSwitcher('want')
        sessionStorage.setItem('secondSwitcherFriend', 'want')
    }

    const onToggleCol = () => {
        setSecondSwitcher('collection')
        sessionStorage.setItem('secondSwitcherFriend', 'collection')
    }

    const onSwitchWanted = () => {
        setSwitcher('created')
        setFixedHeader(true)
    }

    const onSwitchAccepted = () => {
        setSwitcher('accepted')
        setFixedHeader(true)
    }

    const onSwitchBack = () => {
        setSwitcher(null)
        setFixedHeader(true)
    }

    const onClose = () => {
        navigate('/friends')
        dispatch(navActions.setVisible(true))
    }

    const addFriend = () => {
        if (myFriends.includes(userId)) {
            dispatch(authActions.deleteFriend(userId))
        } else {
            dispatch(authActions.addFriend(userId))
        }
    }

    const timeoutWhaleCopyRef = useRef(null) // реф для таймаута говорящего кита
    const onSpeak = useCallback(() => {
        // ф-ция говорящего кита
        clearTimeout(timeoutWhaleCopyRef.current)
        timeoutWhaleCopyRef.current = null
        setVisible(true)
        timeoutWhaleCopyRef.current = setTimeout(() => {
            setVisible(false)
        }, 3000)
    }, [])

    const copyText = (text) => {
        copyTextToClipboard(text)
        onSpeak()
    }

    const bannerRedirect = () => {
        navigate('/')
    }

    let added
    if (myFriends.includes(userId)) {
        added = bookmarkRed
    } else {
        added = bookmark
    }

    if (status !== 'resolved') {
        return (
            <div
                style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Loader />
            </div>
        )
    }

    return (
        <div className={cls.CollectionPage}>
            <Popup visible={visible} text={'Ник скопирован'} />
            {myAllMoments.length === 0 ? (
                <Banner>
                    <div className={cls.BannerContainer}>
                        <Text
                            className={cls.BannerText}
                            align={TextAlign.LEFT}
                            // size={TextSize.S}
                            // weight={TextWeight.SBOLD}
                        >
                            Твои мечты уже в TagLife! Найдем их?
                        </Text>
                        <Button
                            color={ColorEnum.BLUE}
                            className={cls.BannerButton}
                            text={'ГОУ'}
                            onClick={bannerRedirect}
                        />
                    </div>
                </Banner>
            ) : null}
            {!switcher ? (
                <div className={cls.ProfileContainer}>
                    <Button className={cls.Settings} onClick={shareFunc}>
                        <img className={cls.Share} src={share} alt="share" />
                    </Button>
                    <Button onClick={onClose} className={cls.Back}>
                        <img
                            style={{ pointerEvents: 'none', width: '24px' }}
                            src={chevron}
                            alt="chevron"
                        />
                    </Button>
                    <img
                        className={cls.background}
                        alt="background"
                        src={background}
                    />
                    <img className={cls.Avatar} alt="avatar" src={avatar} />
                    <Text
                        className={cls.name}
                        size={TextSize.L}
                        weight={TextWeight.BOLD}
                    >
                        {name}
                    </Text>
                    <Text
                        onClick={() =>
                            copyText(nickname ? `@${nickname}` : `@${id}`)
                        }
                        className={cls.nickname}
                        size={TextSize.S}
                        color={TextColor.TERBLACK}
                    >
                        <div style={{ display: 'flex' }}>
                            {nickname ? `@${nickname}` : `@${id}`}
                            <img
                                style={{ width: '16px', marginLeft: '4px' }}
                                src={copyIcon}
                                alt="copy"
                            />
                        </div>
                    </Text>
                    <Text className={cls.description} weight={TextWeight.REG}>
                        {descr}
                    </Text>
                </div>
            ) : null}
            {!switcher ? (
                <ButtonProfile
                    className={cls.ButtonProfile}
                    counterAccept={counterAccept}
                    onSwitchAccepted={onSwitchAccepted}
                    counterWant={counterCreate}
                    onSwitchWanted={onSwitchWanted}
                    share={shareFunc}
                    addFriend={addFriend}
                    myFriends={myFriends}
                    userId={userId}
                    readonly={true}
                    secondSwitcher={secondSwitcher}
                    onToggleWant={onToggleWant}
                    onToggleCol={onToggleCol}
                    telegram={telegram}
                />
            ) : null}
            {switcher === 'created' ? (
                <GridCollection
                    collection={createdCardData}
                    switcher={switcher}
                    header={header}
                    counterAccept={counterAccept}
                    counterWant={counterCreate}
                    onSwitchBack={onSwitchBack}
                    readonly={true}
                />
            ) : null}
            {switcher === 'accepted' ? (
                <GridCollection
                    collection={acceptedCardData}
                    switcher={switcher}
                    header={header}
                    counterAccept={counterAccept}
                    counterWant={counterCreate}
                    onSwitchBack={onSwitchBack}
                    readonly={true}
                />
            ) : null}
            {!switcher ? (
                <>
                    {secondSwitcher === 'collection' ? (
                        <CollectionContainer
                            margin={myAllMoments.length === 0 ? true : false}
                            collections={parsedCollections}
                            readonly={true}
                        />
                    ) : (
                        <WantContainer
                            myAllMoments={myAllMoments}
                            readonly={true}
                            wantedCardData={wantedCardDataWithMyPicks}
                        />
                    )}
                </>
            ) : null}
        </div>
    )
}

export default OtherProfilePage
