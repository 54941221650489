import { Button } from 'shared/ui/Button'
import cls from './RequestModal.module.scss'
import { useNavigate } from 'react-router-dom'

export const RequestModal = ({ switcher }) => {
    const navigate = useNavigate()

    const onRedirect = () => {
        navigate('/suggest')
    }

    if (switcher === 'created') {
        return (
            <div className={cls.Container}>
                <Button
                    className={cls.Button}
                    onClick={onRedirect}
                    text="Создать свою мечту"
                />
            </div>
        )
    }

    return (
        <div className={cls.Container}>
            <div className={cls.Text}>
                Хочешь увидеть своё достижение в TagLife?
            </div>
            <Button onClick={onRedirect} text="Создать своё достижение" />
        </div>
    )
}
