import { Collection } from 'entities/Collection/ui/Collection'
import { useSelector } from 'react-redux'
import { classNames } from 'shared/lib/classNames/classNames'
import cls from './CollectionContainer.module.scss'
import { Loader } from 'shared/ui/Loader'
import { useTransition } from '@react-spring/web'
import { Button } from 'shared/ui/Button'
import {
    Text,
    TextAlign,
    TextColor,
    TextSize,
    TextWeight,
} from 'shared/ui/Text/Text'
import cup from 'shared/assets/icons/cup-32.svg'
import box from 'shared/assets/icons/box-24.svg'
import myCollection from 'shared/assets/images/my-collection.jpg'
import { Credits } from 'widgets/Credits/Credits'
import { OfferCollectionModal } from 'widgets/OfferCollectionModal'
import { useEffect } from 'react'
import React from 'react'

export const CollectionContainer = (props) => {
    const {
        className,
        collections,
        readonly,
        margin,
        announceCollections,
        openAnnounceFloat,
    } = props

    let collections2
    if (readonly) {
        collections2 = [...collections]
    } else {
        collections2 = [...collections, ...announceCollections]
    }
    // useEffect(() => {
    //     announceCollections.map((item) => {
    //         localStorage.setItem(item.name, true)
    //     })
    //     announceCollections.map((item) => {
    //         if (localStorage.getItem(item.name)) {
    //             collections2.sort((a, b) => a.id - b.id)
    //         }
    //     })
    // }, [])
    console.log(collections2)

    const onRedirect = () => {
        window.open('https://t.me/TagLife_Help')
    }

    const transitions = useTransition(collections2.slice(0, 4), {
        //анимация
        from: { opacity: 0, transform: 'translate3d(0,40px,0)' },
        enter: { opacity: 1, transform: 'translate3d(0,0px,0)' },
        config: { tension: 320, friction: 20 },
        trail: 150,
    })

    // const countReceived = collections.filter((item) => item.received === true).length;

    return (
        <div className={classNames(cls.CollectionContainer, {}, [className])}>
            {/* <div className={cls.Counter}>
                <div className={cls.CounterWrapper}>
                    <img className={cls.IconBox} src={box} alt="box" />
                    <Text
                        className={cls.CollectionCounter}
                        weight={TextWeight.BOLD}
                        align={TextAlign.LEFT}
                    >
                        Коллекции
                    </Text>
                </div>
                <div className={cls.CounterWrapper}>
                    <Text
                        className={cls.CollectionCounter}
                        weight={TextWeight.BOLD}
                        color={TextColor.TERBLACK}
                    >
                        {collections2.length} шт.
                    </Text>
                </div>
            </div> */}
            {transitions((style, item) => {
                return (
                    <Collection
                        style={style}
                        id={item.id}
                        idArray={item.idArray}
                        img={item.img}
                        link={item.link}
                        name={item.name}
                        key={item.id}
                        finded={item.finded}
                        accepted={item.accepted}
                        lastFinded={item.lastFinded}
                        secondFinded={item.secondFinded}
                        level={item.level}
                        progress={item.progress}
                        max={item.max}
                        readonly={readonly}
                        announce={item.announce}
                        openAnnounceFloat={openAnnounceFloat}
                    />
                )
            })}
            {collections2.slice(4).map((item) => {
                return (
                    <Collection
                        id={item.id}
                        idArray={item.idArray}
                        img={item.img}
                        link={item.link}
                        name={item.name}
                        key={item.id}
                        finded={item.finded}
                        accepted={item.accepted}
                        lastFinded={item.lastFinded}
                        secondFinded={item.secondFinded}
                        level={item.level}
                        progress={item.progress}
                        max={item.max}
                        readonly={readonly}
                        announce={item.announce}
                        openAnnounceFloat={openAnnounceFloat}
                    />
                )
            })}
            {!readonly ? (
                <div className={cls.SuggestCollection}>
                    <img
                        src={myCollection}
                        alt={'background'}
                        className={cls.Background}
                    ></img>
                    <div className={cls.SuggestContainer}>
                        <Text
                            className={cls.SuggestText}
                            weight={TextWeight.BOLD}
                            align={TextAlign.LEFT}
                            color={TextColor.WHITE}
                        >
                            Своя коллекция
                        </Text>
                        <Button
                            onClick={onRedirect}
                            className={cls.SuggestButton}
                            text={'Предложить'}
                        ></Button>
                    </div>
                </div>
            ) : null}
            {readonly ? (
                <OfferCollectionModal
                    text={'Твои мечты уже в TagLife! Найдем их?'}
                    buttonText={'Найти мечту'}
                />
            ) : null}
            <Credits margin={margin} mode={readonly ? 'short' : null} />
        </div>
    )
}
