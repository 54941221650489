import { Button } from 'shared/ui/Button'
import cls from './OfferCollectionModal.module.scss'
import { useNavigate } from 'react-router-dom'
import whale from 'shared/assets/images/whaleGreeting.png'
import { classNames } from 'shared/lib/classNames/classNames'

export const OfferCollectionModal = (props) => {
    const { text, buttonText, className } = props
    const navigate = useNavigate()

    const onRedirect = () => {
        navigate('/')
    }

    return (
        <div className={classNames(cls.OfferCollectionModal, {}, [className])}>
            <img className={cls.whale} src={whale} alt="whale" />
            <div className={cls.Text}>{text}</div>
            <Button onClick={onRedirect} text={buttonText} />
        </div>
    )
}
